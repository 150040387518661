.SignUpContCont{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .SignUpContCont{
        flex-direction: column-reverse;
    }
}
.homeCont{
    width: 100%;
    background: var(--main-color);
    padding-bottom: 20px;
}

.homeContainer{
    height: auto;
    padding-top: 150px;
    padding-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.homeCont-left{
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.homeContleftContent{
    width: 80%;
}

.homeCont-left P{
    margin: 20px 0px;
    font-size: var(--text-size2);
    line-height: var(--line-height);
    color: var(--text-color);
    font-weight: var(--font-weight);    
}

.homeCont-left h1{
    font-size: var(--heading-size);
    font-weight: var(--font-weight1);
    color: var(--text-color);
}

.homeCont-leftPara{
    font-size: var(--font-size3) !important;
}

.homeHerobtn{
    display: flex;
    gap: 20px;
    padding: 20px 0px;
    align-items: center;
}

.homeHerobtn button{
    padding: 10px 30px;
    border: none;
    outline: none;
    border: var(--border);
    border-radius: var(--border-radius);
    background: transparent;
    color: var(--text-color);
}

.freeTrialbtn{
    background: var(--btn-color) !important;
    color: var(--main-color) !important;
    font-weight: var(--font-weight1) !important;
}

.timersect{
    display: flex;
    align-items: center;
    gap: 20px;
    line-height: 12px;
}
.Timesect1 h1{
    font-size: 35px !important;
}

.Timesect1 p{
    font-size: var(--font-size3);
}

.homeCont-right{
    width: 50%;
}

.heroshapeImage{
    position: absolute;
    top: 40px;
    right: 0;
    width:480px;
}

.heroSectImage{
    position: relative;
    z-index: 1000;
    width: 550px;
}

.homeImg1{
    width: 100%;
}

.homeServiceSect{
    width: 100%;
}

.servicesTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 70px;
}

.serviceTopleft{
    width: 48%;
}

.serviceTopleft h2{
    color: var(--heading-color);
    font-size: var(--text-size2);
}

.serviceTopleft h1{
    font-size: var(--sec-heading-size);
    font-weight: var(--font-weight1);
    margin: 10px 0px;
    color: var(--main-color);
}
.serviceTopRight{
    width: 48%;
}

.serviceTopRight p{
    font-size: var(--font-size3);
    font-weight: var(--font-weight);
    color: var(--main-color);
    margin-bottom: 20px;
    line-height: var(--line-height);
}

.serviceTopRight button{
    padding: 15px 30px;
    border-radius: var(--border-radius);
    border: none;
    font-weight: var(--font-weight1);
    color: var(--main-color);
    background: var(--btn-color);
    border: var(--border);
    text-transform: uppercase;
}

.servicesBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 60px;
}

.servicesCards{
    width: 33%;
    padding: 20px;
    border: var(--border1);
    border-radius: 2px;
    margin-bottom: 20px;
}

.servicesCards h1{
    font-size: var(--text-size2);
    font-weight: var(--font-weight1);
    color: var(--main-color);
    margin: 20px 0px;
}

.servicesCards p{
    font-size: var(--font-size3);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    
}

.homesect3{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 60px 70px;
    background: var(--main-color);
}

.sect3left{
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sect3left img{
    width: 70%;
}

.sect3Right{
    width: 50%;
}

.sect3Right h1{
    font-size: var(--sec-heading-size);
    color: var(--text-color);
    font-weight: var(--font-weight1);
    margin: 40px 0px;
}

.sect3Right p{
    font-size: var(--font-size3);
    font-weight: var(--font-weight);
    color: var(--text-color);
    margin: 40px 0px;
    line-height: var(--line-height);
}

.sect3Right button{
    padding: 20px 30px;
    border: none;
    border: var(--border);
    background: var(--btn-color);
    border-radius: var(--border-radius);
    font-weight: var(--font-weight1);
}


.homesect4, .homesect4-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 70px;
}

.homesect4left{
    width: 48%;
}

.homesect4left h2{
    color: var(--heading-color);
    font-size: var(--text-size2);
}

.homesect4left h1{
    font-size: var(--sec-heading-size);
    font-weight: var(--font-weight1);
    margin: 10px 0px;
    color: var(--main-color);
    margin: 20px 0px;
}

.homesect4left p{
    font-size: var(--font-size3);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
}

.homesect4left button{
    padding: 20px 30px;
    border: none;
    background-color: var(--btn-color);
    font-weight: var(--font-weight1);
    border-radius: var(--border-radius);
    margin: 20px 0px;
}

.homesect4Right{
    width: 52%;
}

.homesect4Right img{
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .homeContainer{
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }   
    .homeCont-left{
        width: 100%;
        text-align: center;
    }
    .homeCont-right{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .heroshapeImage{
        display: none;
    }
    .heroSectImage{
        width: 80%;
        margin-bottom: 40px;
    }
    .homeHerobtn{
        width: 100%;
        justify-content: center;
    }
    .homeHerobtn button{
        width: 48%;
    }
    .timersect{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .servicesTop{
        flex-wrap: wrap;
    }
    .serviceTopleft{
        width: 100%;
    }
    .serviceTopRight{
        width: 100%;
    }
    .serviceTopRight button{
        width: 100%;
    }
    .servicesCards{
        width: 48%;
    }
    .sect3left{
        width: 100%;
    }
    .sect3Right{
        width: 100%;
    }
    .sect3Right button{
        width: 100%;
    }
    .homesect4{
        flex-direction: column-reverse;
    }
    .homesect4left{
        width: 100%;
    }
    .homesect4Right{
        width: 100%;
    }
    .homesect4-1{
        flex-direction: column;
    }
    .homesect4left button{
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .servicesCards{
        width: 100%;
    }
    .servicesTop{
        padding: 30px;
    }
    .servicesBottom{
        padding: 30px;
    }
    .homesect4, .homesect4-1{
        padding: 30px;
    }
}


.privacyFirstCont{
    background: var(--main-color);
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    padding-bottom: 100px;
}

.privacyFirstCont1{
    text-align: center;
}

.privacyFirstCont1 p{
    font-size: var(--text-size2);
    font-weight: var(--font-weight);
    margin: 20px 0px;
}

.privacyFirstCont1 h1{
    font-size: var(--heading-size);
    font-weight: var(--font-weight1);
}
.FirstPC{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.privacyFirstContent{
    width: 70%;
    border-radius: var(--border-radius);
    background: var(--text-color);
    margin-top: -200px;
    padding: 70px;
}

.vprivacyFirstContent h2{
    font-size: var(--text-size2);
    font-weight: var(--font-weight1);
}

.privacyFirstContent p{
    font-size: var(--text-size3);
    font-weight: var(--font-weight);
    margin: 30px 0px;
}

.bottomContentPf p{
    margin: 10px 0px;
}

.privacyFirstBtn{
    display: flex;
    gap: 20px;
    align-items: center;
}

.privacyFirstBtn button{
    border: none;
    color: var(--main-color);
    font-size: var(--text-size3);
    font-weight: var(--font-weight1);
    border-radius: var(--border-radius);
    background: transparent;
    padding: 20px 30px;
}

.firstPrivacyfbtn{
    background: var(--btn-color) !important;
}


@media screen and (max-width: 1200px) {
    .privacyFirstContent{
        width: 100%;
        padding: 30px;
    }
}
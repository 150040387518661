.pricingSect{
    width: 100%;
    background: #EBEEEF;
    padding: 40px 70px;
    padding-bottom: 60px;
}

.pricingSect h2{
    color: var(--heading-color);
    font-size: var(--text-size2);
    text-align: center;
}

.pricingSect h1{
    font-size: var(--sec-heading-size);
    font-weight: var(--font-weight1);
    margin: 10px 0px;
    color: var(--main-color);
    margin: 20px 0px;
    text-align: center;
}

.pricingCards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 80px;
    align-items: center;
}

.pricingcard{
    width: 35%;
    background: var(--text-color);
    padding: 40px;
    border-radius: var(--border-radius);
}

.priceCardTop{
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
    width: 100%;
}

.priceCardTop img{
    width: 100px;
}

.pricecardTopCont{
    line-height: 20px;
}

.pricecardTopCont h1{
    font-size: var(--sec-heading-size);
    font-weight: var(--font-weight1);
}
.pricecardbtn{
    padding: 10px 30px;
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: var(--text-size3);
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    border-radius: 10px;
    background-color: var(--btn-color);
    border: none;
}

.pricePara{
    font-size: var(--text-size3);
    font-weight: var(--font-weight);
    margin: 20px 0px;
}

.priceRate{
    font-size: 54px !important;
    font-weight: var(--font-weight1) !important;
    text-align: start !important;
}

.priceRate span{
    font-size: 20px;
    font-weight: var(--font-weight);
}

.priceCardInclude{
    font-size: var(--text-size3) !important;
    color: var(--main-color) !important;
    font-weight: var(--font-weight1) !important;
    text-align: start !important;
    margin-bottom: 20px;
}

.cardcont1{
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 20px 0px;
}

.cardcont1 p{
    font-size: var(--text-size3);
    font-size: var(--font-weight);
}


.pricebtn2 button{
    width: 100%;
    border: none;
    background: var(--btn-color);
    font-size: var(--text-size3);
    border-radius: var(--border-radius);
    padding: 15px 0px;
    margin-top: 20px;
}

@media screen and (max-width: 1500px) {
    .pricingcard{
        width: 45%;
    }
}

@media screen and (max-width: 1200px) {
    .pricingcard{
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .pricingSect{
        padding: 30px;
    }
    .pricingcard{
        padding: 20px;
    }
    .priceCardTop{
        margin-top: 40px;
    }
    .priceCardTop img{
        width: 50px;
    }
    .pricecardbtn{
        top: -10px !important;
    }
    .pricecardbtn{
        top: -40px !important;
    }
}

.vpndownCont{
    background: var(--main-color);
    width: 100%;
    height: 600px;
    display: flex;
    padding-top: 70px;
    justify-content: center; 
    align-items: center;
    padding: 30px;
}
.downlCont{
    text-align: center;
}
.downlCont h1{
    font-size: var(--heading-size);
    font-weight: var(--font-weight1);
    color: var(--text-color);
}

.downlCont p{
    font-size: var(--text-size3);
    font-weight: var(--font-weight);
    color: var(--text-color);
    margin: 20px 0px;
}

.downloadbtn{
    display: flex;
    gap: 30px;
    margin-top: 30px;
    align-items: center;
    flex-wrap: wrap;
}

.downloadbtn button{
    padding: 10px 30px;
    display: flex;
    background: transparent;
    border: none;
    border: var(--border);
    color: var(--text-color);
    border-radius: var(--border-radius);
    gap: 20px;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .downloadbtn{
        width: 100%;
        flex-direction: column;
    }
    .downloadbtn button{
        width: 100%;
    }
}
.footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px 70px;
}

.footerleft{
    width: 20%;
}

.footerleft img{
    width: 250px;
}

.footerRight{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 80%;
}

.footerlinks1  h2{
    font-size: 20px;
    font-weight: var(--font-weight1);
    margin-bottom: 30px;
}

.footerlinks1 ul li{
    list-style: none;
    font-size: var(--text-size);
    font-weight: var(--font-weight);
    margin-bottom: 20px;
}

.footerCopyright{
    width: 100%;
    background: var(--btn-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.footerCopyright p{
    font-size: var(--text-size);
    font-weight: var(--font-weight);
}

@media screen and (max-width: 1200px) {
    .footer{
        flex-direction: column-reverse;
    }
    .footerleft{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0px;
    }
    .footerRight{
        width: 100%;
        justify-content: start;
    }
    .footerlinks1{
        margin-bottom: 20px;
        width: 48%;
    }
}


@media screen and (max-width:700px){
    .footer{
        padding: 30px;
    }
}

@media screen and (max-width:400px){
    .footerlinks1{
        width: 100%;
    }
}
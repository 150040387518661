.navbar{
    width: 100%;
    background: transparent;
    position: fixed;
    top: 0;
    z-index: 100000000;
    left: 0;
    background: rgba(7,4,47,0.3);
}
.scrolled{
    background: var(--main-color);
}
.navTop{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 0px;
    height: 40px;
    border-bottom: var(--border);
}

.navTop p{
    font-size: var(--text-size);
    color: var(--text-color);
    font-weight: var(--font-weight);
}

.navBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    position: relative;
    height: 60px;
    padding: 40px 30px;
}

.navlogo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navlogo img{
    width: 250px;
}

.navRight{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.navLinks{
    display: flex;
    gap: 20px;
    align-items: center;
}

.navmenuLink{
    list-style: none;
    text-decoration: none;
    color: var(--text-color);
    font-size: var(--text-color1);
}

.navbtn{
    display: flex;
    gap: 20px;
    align-items: center;
}

.navbtn button {
    border: none;
    outline: none;
    background: transparent;
    border: var(--border);
    padding: 10px 20px;
    border-radius: var(--border-radius);
    color: white;
    font-weight: var(--font-weight);
}

.navbtn2{
    background: var(--text-color) !important;
    color: var(--text-color1) !important;
}

.menuIcon{
    font-size: 30px;
    display: none;
}
.cutbtn{
    display: none;
}
@media screen and (max-width: 1200px) {
    .menuIcon{
        display: block;
        color: var(--text-color);
    }
    .navRight{
        left: -1000px;
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        width:70%;
        align-items: flex-start;
        top: 80px;
        background: var(--main-color) !important;
        height: 100vh;
        transition: 0.5s ease-in;
    }
    .navLinks{
        margin-top: 20px;
    }
    .navmenuLink{
        padding: 10px 0px;
    }
    .navbtn button{
        padding: 10px 40px;
    }
    .cutbtn{
        display: block;
        color: white;
        position: absolute;
        right: 40px;
        top: 0;
    }
    .navRight.open{
        display: flex;
        left: 0;
    }
    .navLinks{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
    }
}


@media screen and (max-width: 500px) {
    .navlogo img{
        width: 200px;
    }
    .navbtn{
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .navbtn button{
        width: 100%;
    }
}
.loginCont{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap !important;
}

.loginLeft{
    background: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}

.loginRight{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginRight img{
    width: 150px;
    margin: 20px 0px;
}

.loginRight h1{
    font-size: var(--sec-heading-size);
    font-weight: 600;
    color: var(--main-color);
    margin-bottom: 20px;
}

.InputForm{
    width: 80%;
}

.loginInput{
    margin: 10px 0px;
}

.loginInput label{
    font-size: var(--text-size3);
    font-weight: 600;
}

.loginInput input{
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    border: none;
    outline: none;
    background: none;
    border-bottom: var(--border1);
    padding: 10px;
}

.loginInput input::placeholder{
    color: var(--main-color);
}

.signinbtn{
    width: 100%;
    height: 50px;
    background: var(--btn-color);
    border: none;
    border-radius: var(--border-radius);
    font-size: var(--text-size2);
    font-weight: var(--font-weight); 
}
.registerlink{
    width: 100%;
    text-align: center;
    margin: 20px 0px;
}
.loginInput p{
    font-size: var(--text-size3);
    font-size: var(--font-weight1);
    text-align: center;
}

.regLink{
    color: var(--main-color);
    font-weight: var(--font-weight1);
}

@media screen and (max-width: 1200px) {
    .loginCont{
        flex-direction: column;
        display: flex;
    }
    .loginLeft{
        height: 50%;
        width: 100% !important;
    }
    .loginLeft img{
        width: 400px;
    }
    .loginRight{
        height: 50%;
        width: 100% !important;
    }
}
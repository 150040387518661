.vpndownCont1{
    background: var(--main-color);
    width: 100%;
    height: 600px;
    display: flex;
    padding-top: 70px;
    padding:0px 30px;
    align-items: center;
    justify-content: center;
}
.downloadbtn1{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}

.downlCont h2{
    font-size: var(--text-size2);
    font-weight: var(--font-weight);
    color: var(--text-color);
    margin-top: 30px;
}

.downlbtn1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.downlbtn1 img{
    width: 80px;
    height: 120px;
}

.iosImg{
    width: 120px !important;
}

.downlbtn1 button{
    border: none;
    background: transparent;
    border: var(--border);
    color: var(--text-color);
    padding: 10px 30px;
    border-radius: var(--border-radius);
    margin-top: 20px;
}

@media screen and (max-width: 1200px) {
    .downlbtn1{
        width: 100%;
    }
    .vpndownCont1{
        height: 700px;
        padding-top: 100px;
    }
    .vpndownCont1{
        height: 900px;
    }
    .downlbtn1 button{
        width: 100%;
    }
}
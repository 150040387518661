.privacySect{
    width: 100%;
    padding: 60px 0px;
    text-align: center;
    background: var(--main-color);
    color: var(--text-color);
    padding: 30px;
}

.privacySect h1{
    margin: 20px 0px;
    font-size: var(--sec-heading-size);
    font-weight: var(--font-weight1);
}

.privacySect p{
    font-size: var(--text-size3);
    font-weight: var(--font-weight);
    margin: 20px 0px;
    line-height: var(--line-height);
}

.privacySect button{
    padding: 10px 30px;
    font-size: var(--text-size3);
    background: var(--btn-color);
    font-weight: var(--font-weight1);
    border-radius: var(--border-radius);
}

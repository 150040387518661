@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins;
  max-width: 100%;
}

button{
  cursor: pointer;
}
:root{
  --main-color: #07042F;
  --heading-size: 68px;
  --sec-heading-size:38px;
  --heading-color: #2A9F06;
  --btn-color: #00EC6A;
  --text-size: 16px;
  --text-size3: 18px;
  --text-size1: 15px;
  --text-size2: 25px;
  --text-color: #ffffff;
  --text-color1: #07042F;
  --font-weight: 400;
  --font-weight1: 800;
  --border-radius: 30px;
  --line-height: 27px;
  --border: 1px solid #ffffff;
  --border1: 1px solid #DEDDDD;
}

@media screen and (max-width: 1200px) {
  :root{
    --text-size2: 18px;
    --heading-size: 38px;
    --text-size3: 16px;
    --sec-heading-size:28px;
    --text-size: 14px;
  }
}


.vpnFeatures{
    width: 100%; 
}

.featuresCont{
    display: flex;
    justify-content: center;
    height: 600px;
    width: 100%;
    background: var(--main-color);
    padding: 30px;
    padding-top: 50px;
    text-align: center;
    align-items: center;
}

.featuresMainContent h2{
    font-size: var(--text-size2);
    font-weight: var(--font-weight);
    color: var(--text-color);   
}

.featuresMainContent h1{
    font-size: var(--heading-size);
    font-weight: var(--font-weight1);
    color: var(--text-color);
}

.featuresMainContent p{
    font-size: var(--text-size3);
    font-weight: var(--font-weight);
    color: var(--text-color);
    line-height: var(--line-height);
    margin: 20px 0px;
}

.featuresSectbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.featuresSectbtn button{
    padding: 10px 30px;
    background: none;
    border-radius: var(--border-radius);
    color: var(--text-color);
    border: none;
    border: var(--border);
    font-weight: var(--font-weight1);
}

.featuresbtn1{
    background: var(--btn-color) !important;
    color: var(--main-color) !important;
}

.servicesTop1{
    width: 100%;
    padding: 40px 0px;
    text-align: center;
}

.serviceTopleft1 h2{
    color: var(--heading-color);
    font-size: var(--text-size2);
}

.serviceTopleft1 h1{
    font-size: var(--sec-heading-size);
    font-weight: var(--font-weight1);
    color: var(--main-color);
    margin: 20px 0px;
}

.servicesBottom1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 60px;
}

.servicesCards1{
    width: 33%;
    padding: 20px;
    border: var(--border1);
    height: 280px;
    border-radius: 2px;
    margin-bottom: 20px;
    background: #F5F5F5;
}

.servicesCards1 h1{
    font-size: var(--text-size2);
    font-weight: var(--font-weight1);
    color: var(--main-color);
    margin: 20px 0px;
}

.servicesCards1 p{
    line-height: var(--line-height);
    font-size: var(--font-size3);
    font-weight: var(--font-weight);
}

@media screen and (max-width: 1200px) {
    .featuresSectbtn{
        width: 100%;
    }
    .featuresSectbtn button{
        width: 48%;
    }
    .servicesCards1{
        width: 48%;
    }
}

@media screen and (max-width: 700px) {
    .servicesCards1{
        width: 100%;
    }
    .servicesBottom1{
        padding: 30px;
    }
    .featuresCont{
        height: 650px;
    }
}